import './App.css';
import React, {useEffect} from 'react';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

function getToken(){
  const url = new URL(window.location.href);
  const accessToken = url.search.replace('?','').split('&').filter(function(el) { 
    if(el.match('access_token') !== null) 
      return true; 
    })[0].split('=')[1]
  return accessToken
}


function setCookie(value) {

  const decodedObject = jwtDecode(value);
		const isBatchManager =
      decodedObject.roles.includes('batch-manager');
  if (isBatchManager) {
    Cookies.set('bm-acciojobs-token', value, {
      domain: 'acciojob.com',
      expires: 365,
      secure: true,
    });
  }
  
  const res = Cookies.set('acciojobs-token', value, {
      domain: 'acciojob.com',
      expires: 365,
      secure: true,
    });
  if(res && res!=undefined){
    window.location.href = 'https://authentication.acciojob.com';
  }
}


function App() { 
  useEffect(() => {     
    setCookie(getToken())
  }, [])

  return (
    <div className="App">
      Automatically logging you in, please see if popup is blocked. If nothing works, <button onClick={setCookie}>Click Here to Login as user </button>
    </div>
  );
}

export default App;
